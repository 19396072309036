// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("@nathanvda/cocoon");
require("expose-loader");
require("navbar");
require("notification");
require("particles/particles");
require("particles/main");
require("bulma");
require("bulma-iconpicker");
require("bulma-timeline/dist/css/bulma-timeline.min.css");
require("contracts_dynamic_fields");
require("form-validations");
require("file-picker");
require("table-filter");
require("table-filter/item-select");
require("read-excel-file");
require("utils");
require("toastr-js");
import "sweet-alert";
import "mmenu-js/dist/mmenu.css";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

window.initMap = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
