import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    let branch = $("#engineering_maintenance_branch_id");
    let stations = $("#engineering_maintenance_station_id");

    branch.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });

    branch.on("select2:unselect", () => {
      stations.val("");
      stations.children().remove();
    });
  }

  fillStationsOptions() {
    let selected_branch = $("#engineering_maintenance_branch_id").val();
    let stations_field = $("#engineering_maintenance_station_id");
    Rails.ajax({
      url: `/stations_list/${selected_branch}`,
      type: "get",
      data: "",
      success: function (data) {
        stations_field.children().remove();
        data.forEach((station) => {
          var new_option = new Option(
            `${station.name} - ${station.description}`,
            station.id,
            false,
            false
          );
          stations_field.append(new_option);
        });
        stations_field.val("");
      },
      error: function (data) {},
    });
  }
}
