import { Controller } from "stimulus";
import $ from "jquery";
import {
  verifyAddressFields,
  verifyAddressValues,
} from "../../form-utilities/verifyAddressFields";

export default class extends Controller {
  static targets = [
    "addressFull",
    "radiusInput",
    "saveButton",
    "columnAddress",
    "latitude",
    "longitude",
  ];

  timers = [];
  connect() {
    var defaultConfig = {
      placeholder: "Selecionar...",
      language: "pt-BR",
      allowClear: true,
      tags: false,
    };

    let states = $("#dynamic_state");
    let cities = $("#dynamic_city");

    this.initialControlForEditingMode();
    this.enableButtonsIfFilledFields();

    states.select2(defaultConfig).on("select2:unselecting", function (e) {
      var self = $(this);
      setTimeout(() => {
        self.select2("close");
      }, 0);
    });

    states.on("select2:select", function (e) {
      var self = $(this);
      setTimeout(() => {
        self.select2("close");
      }, 0);
    });

    cities.select2(defaultConfig).on("select2:unselecting", function (e) {
      var self = $(this);
      setTimeout(() => {
        self.select2("close");
      }, 0);
    });
    /**
     *  Cria evento forçado para o select2
     */
    states.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });

    states.on("select2:unselect", () => {
      cities.val("");
      cities.children().remove();
      this.disableAllInputs();
      this.clearAllInputs();
    });

    states.on("change.select2", () => {
      cities.val("");
      cities.children().remove();
      this.disableAllInputs();
      this.clearAllInputs();
    });

    cities.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });

    cities.on("select2:unselect", () => {
      this.disableAllInputs();
      this.clearAllInputs();
    });
  }

  initialControlForEditingMode() {
    if (verifyAddressValues(this.addressFullTargets)) {
      this.saveButtonTarget.disabled = false;
      this.enableInputs();
      setTimeout(() => {
        this.dragMarker();
        setTimeout(() => {
          if (!window.circle) {
            this.drawCircle();
            window.circle.setVisible(true);
            window.map.setZoom(15);
          }
        }, 100);
      }, 150);
    } else {
      this.saveButtonTarget.disabled = true;
    }
  }

  enableButtonsIfFilledFields() {
    this.addressFullTargets.forEach((field) => {
      field.addEventListener("change", () => {
        if (verifyAddressFields(this.addressFullTargets)) {
          this.saveButtonTarget.disabled = false;
        } else {
          this.saveButtonTarget.disabled = true;
        }
      });
    });
  }

  selectState() {
    let cities = $("#dynamic_city");
    let chosenState = document.getElementById("dynamic_state").value;
    this.fillCitiesOptions(chosenState, cities);
  }

  selectCity() {
    this.enableInputs();
    this.dragMarker();
    window.marker.setDraggable(true);
    if (!window.circle) {
      this.drawCircle();
      window.circle.setVisible(true);
    } else {
      window.circle.setVisible(true);
    }
  }

  enableInputs() {
    this.addressFullTargets.forEach((element, index) => {
      element.disabled = false;
    });
    this.radiusInputTarget.disabled = false;
    this.showColumnAddress();
  }

  disableAllInputs() {
    this.addressFullTargets.forEach((element, index) => {
      element.disabled = true;
      this.radiusInputTarget.disabled = true;
    });
    this.hiddenColumnAddress();
  }

  clearAllInputs() {
    this.addressFullTargets.forEach((element, index) => {
      element.value = "";
    });
    window.marker.setDraggable(false);
    if (window.circle) window.circle.setVisible(false);
  }

  clearButton() {
    let states = $("#dynamic_state");
    let cities = $("#dynamic_city");
    var defaultConfig = {
      placeholder: "Selecionar...",
      language: "pt-BR",
      allowClear: true,
      tags: false,
    };

    states.val("");
    cities.val("");
    states.select2(defaultConfig);
    cities.select2(defaultConfig);

    this.clearAllInputs();
    this.saveButtonTarget.disabled = true;
    this.hiddenColumnAddress();
    window.marker.setDraggable(false);
    window.circle.setVisible(false);
  }

  showColumnAddress() {
    this.columnAddressTarget.classList.remove("is-hidden");
  }

  hiddenColumnAddress() {
    this.columnAddressTarget.classList.add("is-hidden");
  }

  fillCitiesOptions(selected_state, cities_field) {
    Rails.ajax({
      url: `/state/${selected_state}/cities`,
      type: "get",
      data: "",
      success: function (data) {
        cities_field.children().remove();
        data.forEach((city) => {
          var new_option = new Option(city.name, city.id, false, false);
          cities_field.append(new_option);
        });
        cities_field.val("");
      },
      error: function (data) {},
    });
  }

  verify() {
    if (this.addressFullTargets[0].value && this.addressFullTargets[2].value) {
      this.debounce(this.setCoord, 600);
    }
  }

  debounce(func, wait) {
    if (this.timers.length > 0) {
      this.timers.forEach((timer) => {
        clearTimeout(timer);
        this.timers.shift();
      });
    }
    this.timers.push(
      setTimeout(func(this.latitudeTarget, this.longitudeTarget), wait)
    );
  }

  refreshMarker() {
    var position;
    if (this.latitudeTarget.value && this.longitudeTarget.value) {
      position = new google.maps.LatLng(
        this.latitudeTarget.value,
        this.longitudeTarget.value
      );
    } else {
      position = window.map.getCenter();
    }

    window.marker.setPosition(position);

    window.map.panTo(window.marker.getPosition());
  }

  dragMarker() {
    var onDrag = function () {
      var latlng = marker.getPosition();
      var newlat = Math.round(latlng.lat() * 1000000) / 1000000;
      var newlng = Math.round(latlng.lng() * 1000000) / 1000000;
      this.latitudeTarget.value = newlat;
      this.longitudeTarget.value = newlng;
    };
    var afterDrag = function () {
      window.map.panTo(marker.getPosition());
    };
    window.marker.addListener("drag", onDrag.bind(this));
    // When drag ends, center (pan) the map on the marker position
    window.marker.addListener("dragend", afterDrag.bind(this));
  }

  drawCircle() {
    let circle = new google.maps.Circle({
      map: window.map,
      radius: parseInt(this.radiusInputTarget.value, 10),
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      visible: false,
    });

    circle.bindTo("center", window.marker, "position");
    window.circle = circle;
  }

  updateRadius() {
    window.circle.setRadius(parseInt(this.radiusInputTarget.value, 10));
  }

  setCoord(lat, lon) {
    Rails.ajax({
      url: `/geolocation/${$("#dynamic_street").val()},${$(
        "#dynamic_number"
      ).val()},${$("#dynamic_district").val()}, ${$(
        "#dynamic_city option:selected"
      ).text()}, ${$("#dynamic_state option:selected").text()}`,
      type: "get",
      data: "",
      success: function (data) {
        lat.value = data.lat;
        lon.value = data.lng;
        lat.dispatchEvent(new Event("change"));
        lon.dispatchEvent(new Event("change"));
      },
      error: function (data) {
        console.log("erro");
        console.log(data);
      },
    });
  }
}
