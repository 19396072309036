import Swal from "sweetalert2";
import Rails from "@rails/ujs";

window.Swal = Swal;

// Behavior after click to confirm button
const confirmed = (element, result) => {
  if (result.isDenied) {
    if (!!element.getAttribute("data-remote")) {
      let reloadAfterSuccess = !!element.getAttribute("data-reload");

      $.ajax({
        method: element.getAttribute("data-method") || "GET",
        url: element.getAttribute("href"),
        dataType: "json",
        success: function (result) {
          Swal.fire("Success!", result.message || "", "success").then(
            (_result) => {
              if (reloadAfterSuccess) {
                window.location.reload();
              }
            }
          );
        },
        error: function (xhr) {
          let title = "Erro!";
          let message = "Algo deu errado! Por favor, tente mais tarde.";

          if (xhr.responseJSON && xhr.responseJSON.message) {
            message = xhr.responseJSON.message;
          }

          Swal.fire(title, message, "error");
        },
      });
    } else {
      element.removeAttribute("data-confirm-swal");
      element.click();
    }
  }
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  const message = element.getAttribute("data-confirm-swal");
  const text = element.getAttribute("data-text");
  const confimationButton = element.getAttribute("data-confirmation-button");

  Swal.fire({
    title: message || "Você tem certeza?",
    text: text || "",
    icon: confimationButton ? "success" : "warning",
    denyButtonColor: confimationButton ? "#48c78e" : "",
    showConfirmButton: false,
    showCancelButton: true,
    showDenyButton: true,
    denyButtonText: "Sim",
    cancelButtonText: "Não",
    reverseButtons: true,
  }).then((result) => confirmed(element, result));
};

const allowAction = (element) => {
  if (element.getAttribute("data-confirm-swal") === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, "a[data-confirm-swal]", "click", handleConfirm);
