import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let typeSelect = $('#type-select')
    let showf = this.show
    let hidef = this.hide
    typeSelect.on("select2:select", (e) => {
      if (e.target.selectedOptions[0].innerText == 'Ral') {
        showf()
      } else {
        hidef()
      }
    })
    typeSelect.on("select2:unselect", (e) => {
      hidef()
    })
    this.checkTypeSelect(typeSelect)
  }
  checkTypeSelect(typeSelect) {
    if (typeSelect[0].value == 'Ral') {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    $('#recs-field').removeClass('is-hidden')
  }

  hide() {
    $('#recs-field').addClass('is-hidden')
    $('#recs-select').val([]).trigger('change')
  }
}
