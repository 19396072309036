function verifyAddressFields(fields) {
  var result = [];
  fields.forEach((field) => {
    result.push(!field.disabled);
    field.value ? result.push(true) : result.push(false);
  });
  return !result.includes(false);
}

function verifyAddressValues(fields) {
  var result = [];
  fields.forEach((field) => {
    if (field.value) {
      result.push(true);
    } else {
      result.push(false);
    }
  });
  return !result.includes(false);
}

export { verifyAddressFields, verifyAddressValues };
