import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["unity"];

  connect() {
    var defaultConfig = {
      placeholder: "Selecionar...",
      language: "pt-BR",
      allowClear: true,
    };

    var users = $("#select_users");
    var projects_tasks = $("#select_projects_task");
    var tasks = $("#select_tasks");

    users.select2(defaultConfig).on("select2:unselecting", function (e) {
      var self = $(this);
      setTimeout(function () {
        self.select2("close");
      }, 0);
    });

    tasks.select2(defaultConfig).on("select2:unselecting", function (e) {
      var self = $(this);
      setTimeout(function () {
        self.select2("close");
      }, 0);
    });

    projects_tasks
      .select2(defaultConfig)
      .on("select2:unselecting", function (e) {
        var self = $(this);
        setTimeout(function () {
          self.select2("close");
        }, 0);
      });

    tasks.on("change", () => {
      if (tasks.val()) {
        this.getUnity(tasks.val());
      } else {
        this.unityTarget.innerText = "";
      }
    });
  }

  getUnity(id) {
    var unity = this.unityTarget;
    Rails.ajax({
      url: `/contracts_tasks/unity/${id}`,
      type: "get",
      data: "",
      success: function (data) {
        unity.innerText = data;
      },
      error: function (data) {},
    });
  }
}
