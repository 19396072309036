document.addEventListener("turbolinks:load", () => {
  importFileButtonList = document.querySelectorAll(".import-file-button");
  let fileInputList = document.querySelectorAll(".task-file input[type=file]");
  if (importFileButtonList) {
    let html = document.querySelector("html");
    let modalList = document.querySelectorAll(".modal");
    html.classList.remove("is-clipped");
    importFileButtonList.forEach((importFileButton, i) => {
      importFileButton.addEventListener("click", function (event) {
        event.preventDefault();
        let modalBackground = modalList[i].firstElementChild;

        modalList[i].classList.add("is-active");
        html.classList.add("is-clipped");
        modalBackground.addEventListener("click", function (e) {
          e.preventDefault();
          modalList[i].classList.remove("is-active");
          html.classList.remove("is-clipped");
        });

        modalList[i]
          .querySelector(".modal-close")
          .addEventListener("click", function (e) {
            e.preventDefault();
            modalList[i].classList.remove("is-active");
            html.classList.remove("is-clipped");
          });
      });
    });
  }
  if (fileInputList) {
    fileInputList.forEach(
      (fileInput) =>
        (fileInput.onchange = () => {
          if (fileInput.files.length > 0) {
            let fileName = fileInput.parentElement.lastElementChild;
            let name = Array.from(fileInput.files)
              .map((file) => file.name)
              .join(" | ");
            fileName.textContent = name;
          }
        })
    );
  }
});
