document.addEventListener("turbolinks:load", () => {
  function notificationTimeOut(element, interval) {
    if (element.children.length > 0) {
      setTimeout(() => {
        element.children[0].style.transition =
          "opacity " + interval / 1000 / 2 + "s ease";
        element.children[0].style.opacity = "0";
        setTimeout(() => {
          if (element.children.length > 0) {
            element.removeChild(element.children[0]);
          }
        }, speed);
      }, interval);
      setTimeout(() => {
        if (element.children.length > 1) {
          notificationTimeOut(element, interval);
        }
      }, interval + 250);
    }
  }

  try {
    parentNotification = document.getElementById("notifications");
    deleteButtons = document.querySelectorAll(".delete");
    speed = 1000;
    seconds = speed / 1000;

    notificationTimeOut(parentNotification, 5000);

    for (let index = 0; index < parentNotification.children.length; index++) {
      if (parentNotification.children.length > 0) {
        deleteButtons[index].addEventListener("click", () => {
          deleteButtons[
            index
          ].parentElement.parentElement.parentElement.style.transition =
            "opacity " + seconds + "s ease";
          deleteButtons[
            index
          ].parentElement.parentElement.parentElement.style.opacity = "0";
          setTimeout(() => {
            deleteButtons[
              index
            ].parentElement.parentElement.parentElement.parentElement.removeChild(
              deleteButtons[index].parentElement.parentElement.parentElement
            );
          }, speed);
        });
      }
    }
  } catch (error) {}
});
