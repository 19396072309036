import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["branch", "stations"];

  connect() {
    let branch = $(this.branchTarget);
    let stations = $(this.stationsTarget);

    branch.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });
    stations.on("select2:open", (e) => {
      setTimeout(function () {
        document
          .querySelector(".select2-container--open .select2-search__field")
          .focus();
      }, 0);
    });

    stations.select2({
      language: "pt-BR",
      placeholder: "Selecionar...",
      ajax: {
        url: `/stations_list/`,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term,
            branch_id: branch.val()
          };
        },
        processResults: (data) => {
          return {
            results: data.map((item) => {
              return { id: item.id, text: item.name };
            })
          };
        },
        cache: true
      },
      minimumInputLength: 1
    });
  }

  disconnect() {
    $(this.stationsTarget).select2('destroy');
  }
}
