import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {}

  verifyTabs() {
    if (this.tabTargets.length != this.contentTargets.length) {
      console.log(
        "Quantidade de abas não corresponde à quantidade de conteúdos"
      );
      return false;
    }
    return true;
  }

  switch() {
    if (this.verifyTabs()) {
      this.changeTab();
    }
  }

  changeTab() {
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle("is-active");
    });

    this.changeContent();
  }

  changeContent() {
    this.contentTargets.forEach((content) => {
      content.classList.toggle("is-hidden");
    });
  }
}
