import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  connect() {
    window.addEventListener("trix-file-accept", this.handleFileAccept);
    this.hideAttachButton();
  }

  disconnect() {
    window.removeEventListener("trix-file-accept", this.handleFileAccept);
  }

  hideAttachButton() {
    const attachButton = document.querySelector(
      "trix-toolbar button.trix-button--icon-attach"
    );
    const parentElement = attachButton?.closest(".trix-button-group");
    this.hideElement(parentElement);
  }

  hideElement(element) {
    element && (element.style.display = "none");
  }

  handleFileAccept = (event) => {
    event.preventDefault();
    Swal.fire("Ainda não é possível adicionar arquivos!");
  };
}
