import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectClient", "vivoFields", "vtalFields", "claroFields"];

  connect() {
    this.selectClientContract();
  }

  disableInputs(inputs) {
    let list = Array.from(inputs)
    list.forEach(input => {
      input.disabled = true
    });
  }

  enableInputs(inputs) {
    let list = Array.from(inputs)
    list.forEach(input => {
      input.disabled = false
    });
  }

  selectClientContract() {
    switch (
      this.selectClientTarget.options[this.selectClientTarget.selectedIndex]
        .innerHTML
    ) {
      case "Vivo":
        this.claroFieldsTarget.classList.add("is-hidden");
        this.vtalFieldsTarget.classList.add("is-hidden");
        this.vivoFieldsTarget.classList.remove("is-hidden");

        this.enableInputs(this.vivoFieldsTarget.getElementsByTagName('input'))
        this.disableInputs(this.claroFieldsTarget.getElementsByTagName('input'))
        this.disableInputs(this.vtalFieldsTarget.getElementsByTagName('input'))

        break;
      case "Claro":
        this.vivoFieldsTarget.classList.add("is-hidden");
        this.vtalFieldsTarget.classList.add("is-hidden");
        this.claroFieldsTarget.classList.remove("is-hidden");

        this.disableInputs(this.vivoFieldsTarget.getElementsByTagName('input'))
        this.enableInputs(this.claroFieldsTarget.getElementsByTagName('input'))
        this.disableInputs(this.vtalFieldsTarget.getElementsByTagName('input'))
        break;
      case "V.tal":
        this.claroFieldsTarget.classList.add("is-hidden");
        this.vivoFieldsTarget.classList.add("is-hidden");
        this.vtalFieldsTarget.classList.remove("is-hidden");

        this.disableInputs(this.vivoFieldsTarget.getElementsByTagName('input'))
        this.disableInputs(this.claroFieldsTarget.getElementsByTagName('input'))
        this.enableInputs(this.vtalFieldsTarget.getElementsByTagName('input'))
        break;
      case "":
        if (!this.vivoFieldsTarget.classList.contains("is-hidden")) {
          this.vivoFieldsTarget.classList.add("is-hidden");
        }
        if (!this.vivoFieldsTarget.classList.contains("is-hidden")) {
          this.vivoFieldsTarget.classList.add("is-hidden");
        }
        break;
      default:
        this.claroFieldsTarget.classList.add("is-hidden");
        this.vivoFieldsTarget.classList.add("is-hidden");
        this.vtalFieldsTarget.classList.add("is-hidden");
        break;
    }
  }
}
