import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["circuit", "protocol", "locality"];
  connect() {
    let segment = $("#select_segment");
    let opened_at = $("#engineering_maintenance_opened_at");
    segment.attr("data-action", "change->segments#selectSegment");
    opened_at.attr("data-action", "change->segments#selectSegment");

    let promised_to = document.getElementById(
      "engineering_maintenance_promised_to"
    );

    segment.on("select2:select", function (e) {
      var self = $(this);
      setTimeout(() => {
        self.select2("close");
      }, 0);
    });

    /*
     *  Cria evento forçado para o select2
     */
    segment.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });

    segment.on("select2:unselect", () => {
      promised_to.value = "";
    });

    if (segment.val() != "") {
      this.selectSegment();
    }
  }

  selectSegment() {
    let promised_to = document.getElementById(
      "engineering_maintenance_promised_to"
    );
    let selected_segment = document.getElementById("select_segment").value;
    if (selected_segment == "") {
      promised_to.value = "";
    } else {
      this.b2bFields();
      this.fillPromisedTo(selected_segment);
    }
  }

  b2bFields() {
    if (
      document.getElementById("select_segment").selectedOptions[0].text == "B2B"
    ) {
      this.showB2bFields();
    } else {
      this.hideB2bFields();
    }
  }

  showB2bFields() {
    this.circuitTarget.closest(".is-horizontal").classList.remove("is-hidden");
    this.circuitTarget.disabled = false;
    this.localityTarget.disabled = false;
    this.protocolTarget.disabled = false;
  }

  hideB2bFields() {
    this.circuitTarget.closest(".is-horizontal").classList.add("is-hidden");
    this.circuitTarget.disabled = true;
    this.localityTarget.disabled = true;
    this.protocolTarget.disabled = true;
  }

  fillPromisedTo(selected_segment) {
    Rails.ajax({
      url: `/segments/${selected_segment}/deadline`,
      type: "get",
      data: "",
      success: function (data) {
        let promised_to = document.getElementById(
          "engineering_maintenance_promised_to"
        );
        let opened_at = document.getElementById(
          "engineering_maintenance_opened_at"
        );
        let opened_at_date = new Date(opened_at.value).getTime();
        let new_date = new Date(opened_at_date + (data.deadline - 180) * 60000);
        let new_date_value = new_date.toISOString().split(".")[0];
        promised_to.value = new_date_value;
      },
      error: function (data) {},
    });
  }
}
