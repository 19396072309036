import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "button"];

  connect() {

  }

  togglePassword() {
    if (this.fieldTarget.type == 'password')
      this.fieldTarget.type = 'text'
    else
      this.fieldTarget.type = 'password'

    this.buttonTarget.classList.toggle('fa-eye')
    this.buttonTarget.classList.toggle('fa-eye-slash')
  }
}
