import { Controller } from "stimulus";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm";
import "photoswipe/dist/photoswipe.css";

export default class extends Controller {
  static targets = ["gallery"];
  static values = {
    children: String,
  };

  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: `#${this.galleryTarget.id}`,
      children: this.childrenValue,
      pswpModule: () => PhotoSwipe,
      closeTitle: "Fechar",
      zoomTitle: "Zoom",
      arrowPrevTitle: "Anterior",
      arrowNextTitle: "Próxima",
      errorMsg: "A imagem não pôde ser carregada",
    });
    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",
        // SVG with outline
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
          outlineID: "pswp__icn-download",
        },
        onInit: (el, pswp) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");

          pswp.on("change", () => {
            el.href = pswp.currSlide.data.src;
          });
        },
      });
    });
    lightbox.init();
  }
}
