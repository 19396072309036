import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "map",
    "static",
    "dynamic",
    "editable",
    "list",
    "checkIn",
    "taskCheckIn",
    "partCheckIn",
    "latitude",
    "longitude",
    "maintenance",
    "engineering",
    "engineeringMaintenance",
    "project",
    "part",
    "ba",
  ];
  static values = {
    lat: Number,
    lon: Number,
  };
  mapOptions = {
    zoom: 8,
    minZoom: 5,
    maxZoom: 20,
  };

  connect() {
    this.verifyMapType();
  }

  verifyMapType() {
    this.initMap();
    setTimeout(() => {
      if (this.hasStaticTarget) {
        if (this.hasListTarget) {
          this.fetchProjectsListCoords(this.insertMarkers);
        } else if (!this.hasCheckInTarget && this.hasMaintenanceTarget) {
          this.fetchMaintenanceCheckInListCoords(this.insertMarkers);
        } else if (!this.hasPartCheckInTarget && this.hasEngineeringTarget) {
          this.fetchEngineeringCoords(this.insertMarkers);
        } else if (this.hasEngineeringMaintenanceTarget) {
          this.fetchEngineeringMaintenanceCoords(this.insertMarkers);
        } else if (this.hasPartCheckInTarget) {
          this.fetchEngineeringCheckInListCoords(this.insertMarkers);
        } else if (this.hasCheckInTarget) {
          this.fetchCheckInListCoords(this.insertMarkers);
        } else if (this.hasTaskCheckInTarget) {
          this.fetchTaskCheckInListCoords(this.insertMarkers);
        } else {
          this.fetchProjectCoords(this.insertMarkers);
        }
      } else if (this.hasDynamicTarget) {
        if (this.hasEditableTarget) {
          this.latValue = this.latitudeTarget.value;
          this.lonValue = this.longitudeTarget.value;
          this.updateLocation();
          this.initializeMarker();
        } else {
          this.setInitialLocation();
        }
      }
    }, 140);
  }

  initMap() {
    if (typeof google != "undefined") {
      this.map(
        Object.assign(this.mapOptions, {
          center: new google.maps.LatLng(-9.464688, -39.650849),
        })
      );
    }
  }

  map(mapOptions) {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, mapOptions);
      window.map = this._map;
    }
  }

  updateLocation() {
    this._map.panTo(new google.maps.LatLng(this.latValue, this.lonValue));
    this._map.setZoom(11);
  }

  initializeMarker() {
    var position = new google.maps.LatLng(this.latValue, this.lonValue);
    var markerOptions = {
      position,
      animation: google.maps.Animation.DROP,
      map: this._map,
    };

    var marker = new google.maps.Marker(markerOptions);
    this.hasEditableTarget
      ? marker.setDraggable(true)
      : marker.setDraggable(false);
    window.marker = marker;
  }

  setInitialLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latValue = position.coords.latitude;
        this.lonValue = position.coords.longitude;
        let myCoords = new google.maps.LatLng(this.latValue, this.lonValue);

        this._map.setZoom(15);
        this._map.panTo(myCoords);
        this.initializeMarker();
      });
    }
  }

  fetchTaskCheckInListCoords(callback) {
    var urlComplementation = "";
    if (document.URL.split("?")[1])
      urlComplementation = `?${document.URL.split("?")[1]}`;

    Rails.ajax({
      url:
        `/projects_tasks_coords/${document.URL.split("?")[0]
          .split("/")
          .last()}` + urlComplementation,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map, true);
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchMaintenanceCheckInListCoords(callback) {
    var urlComplementation = "";
    if (document.URL.split("?")[1])
      urlComplementation = `?${document.URL.split("?")[1]}`;

    Rails.ajax({
      url:
        `/maintenance_coords/${document.URL.split("?")[0].split("/").last()}` +
        urlComplementation,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map, true, "M");
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchEngineeringMaintenanceCoords(callback) {
    var urlComplementation = "";
    if (document.URL.split("?")[1])
      urlComplementation = `?${document.URL.split("?")[1]}`;

    Rails.ajax({
      url:
        `/engineering_maintenance_coords/${document.URL.split("?")[0].split("/").last()}` +
        urlComplementation,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map, true, "E");
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchEngineeringCoords(callback) {
    var urlComplementation = "";
    if (document.URL.split("?")[1])
      urlComplementation = `?${document.URL.split("?")[1]}`;

    Rails.ajax({
      url:
        `/engineering_coords/${document.URL.split("?")[0].split("/").last()}` +
        urlComplementation,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map, true, "E");
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchEngineeringCheckInListCoords(callback) {
    var urlComplementation = "";
    if (document.URL.split("?")[1])
      urlComplementation = `?${document.URL.split("?")[1]}`;

    Rails.ajax({
      url:
        `/parts_coords/${document.URL.split("?")[0].split("/").last()}` +
        urlComplementation,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map, true, "E");
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchCheckInListCoords(callback) {
    var params = document.URL.split("?");
    if (this.hasMaintenanceTarget) {
      var uri = `/reports/maintenances_check_ins_coords`;
    } else if (this.hasPartTarget) {
      var uri = `/reports/engineerings_check_ins_coords`;
    } else if (this.hasBaTarget) {
      var uri = `/reports/engineering_maintenances_check_ins_coords`;
    } else {
      var uri = `/reports/projects_check_ins_coords`;
    }
    if (params.length > 1) {
      var url = `${uri}?${document.URL.split("?").last()}`;
    } else {
      var url = `${uri}?today=true`;
    }
    Rails.ajax({
      url: url,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, this._map);
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchProjectCoords(callback) {
    var map = this._map;
    Rails.ajax({
      url: this.hasProjectTarget
        ? `/project_coords/${document.URL.split("/").last()}?type=project`
        : `/project_coords/${document.URL.split("/").last()}?type=maintenance`,
      type: "get",
      data: "",
      success: (data) => {
        callback(data, map, true);
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  fetchProjectsListCoords(callback) {
    var map = this._map;
    Rails.ajax({
      url: "/projects_list_coords/",
      type: "get",
      data: "",
      success: function (data) {
        callback(data, map, true);
      },
      error: function (data) {
        console.log(data);
      },
    });
  }

  insertMarkers(markersList, map, circle, label = "P") {
    var bounds = new google.maps.LatLngBounds();
    var position, marker, circle;

    markersList.forEach((coords) => {
      position = new google.maps.LatLng(coords.latitude, coords.longitude);
      marker = new google.maps.Marker({
        position: position,
        animation: google.maps.Animation.DROP,
        label: "C",
        map,
        draggable: false,
      });
      bounds.extend(position);
      if (circle && coords.radius) {
        circle = new google.maps.Circle({
          map: map,
          radius: parseInt(coords.radius, 10),
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        circle.bindTo("center", marker, "position");
        marker.setLabel(label);
      }
    });

    if (markersList.length > 1) {
      map.fitBounds(bounds);
    } else {
      map.panTo(position);
      map.setZoom(15);
    }
  }
}
