import { Controller } from "stimulus";
import readXlsxFile from "read-excel-file";

export default class extends Controller {
  static targets = [
    "input",
    "filename",
    "container",
    "attachments",
    "inputFileTrashButton",
    "clearButton",
    "importButton"
  ];

  connect() {}

  getFileName() {
    if (this.inputTarget.files[0]) {
      this.filenameTarget.innerText = this.inputTarget.files[0].name;
      this.clearButtonTarget.classList.remove('is-hidden');
      this.importButtonTarget.removeAttribute('disabled');
    } else {
      this.filenameTarget.innerText = "Nenhum arquivo selecionado";
      this.importButtonTarget.setAttribute('disabled');
      this.removeTable();
    }
  }

  getAttachments() {
    const filesNames = Array.from(this.inputTarget.files).map(file => file.name)
    filesNames.forEach(name => this.appendPreview(name))
    this.inputFileTrashButtonTarget.classList.remove('is-hidden')
  }

  appendPreview(fileName) {
    var pathElement = document.createElement("p")
    pathElement.innerText = fileName
    this.attachmentsTarget.appendChild(pathElement)
  }

  removeAllAttachmentsPreview() {
    this.inputTarget.value = ""
    while (this.attachmentsTarget.firstChild) {
      this.attachmentsTarget.removeChild(this.attachmentsTarget.lastChild)
    }
    this.inputFileTrashButtonTarget.classList.add('is-hidden')
  }

  openXlsx() {
    if (this.inputTarget.files[0]) {
      readXlsxFile(this.inputTarget.files[0]).then((rows) => {
        this.createTable(rows);
      });
    }
  }

  parseXlsx() {
    if (this.inputTarget.files[0]) {
      readXlsxFile(this.inputTarget.files[0], { map, trim: false }).then(
        (rows) => console.log(rows)
      );
    }
  }

  removeTable() {
    this.containerTarget.previousElementSibling.remove();
    this.containerTarget.children[0].remove();
  }

  createTable(rows) {
    if (this.containerTarget.children[0]) this.removeTable();
    let title = document.createElement("h2");
    let table = document.createElement("table");

    title.classList.add("title", "is-5", "mt-3");
    table.classList.add(
      "table",
      "has-text-centered",
      "is-striped",
      "is-hoverable",
      "is-bordered",
      "is-size-7",
      "is-scrollable-y"
    );

    title.innerText = `Pré-visualização (${
      rows.length - 1
    } itens para importação)`;

    this.containerTarget.before(title);
    this.containerTarget.appendChild(table);

    rows.forEach((row, index) => {
      if (index == 0) {
        let thead = document.createElement("thead");
        table.appendChild(thead);
        let tr = document.createElement("tr");
        thead.appendChild(tr);

        row.forEach((cell) => {
          let th = document.createElement("th");
          th.innerText = cell;
          th.classList.add("is-narrow");
          tr.appendChild(th);
        });
      } else {
        let tbody = document.createElement("tbody");
        table.appendChild(tbody);
        let tr = document.createElement("tr");
        tbody.appendChild(tr);

        row.forEach((cell) => {
          let td = document.createElement("td");
          if (cell != null && cell.length > 100) {
            cell = cell.slice(0, 70) + "...";
          }
          if (cell != null && new Date(cell).getFullYear() > 2020) {
            cell = new Date(cell).toLocaleDateString()
          }
          td.innerText = cell;
          td.classList.add("is-narrow");
          tr.appendChild(td);
        });
      }
    });
  }
}
