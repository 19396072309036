import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loader"];
  connect() {
    document.addEventListener("page:fetch", () => this.show());
    document.addEventListener("turbolinks:click", () => this.show());
    document.addEventListener("turbolinks:load", () =>
      setTimeout(this.hide(), 200)
    );
  }

  show() {
    this.loaderTarget.classList.remove("is-hidden");
  }

  hide() {
    this.loaderTarget.classList.add("is-hidden");
  }
}
