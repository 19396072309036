import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["clientName", "vivoField", "vtalField"];

  connect() {
    this.showClientInformation();
  }

  getClientName() {
    return this.clientNameTarget.getAttribute("value");
  }

  showClientInformation() {
    this.getClientName() == "Vivo"
      ? this.vivoFieldTarget.classList.remove("is-hidden")
      : null;
    this.getClientName() == "V.tal"
      ? this.vtalFieldTarget.classList.remove("is-hidden")
      : null;
  }
}
