import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "tags"];
  static values = {
    placeholder: { type: String, default: "Selecionar..." },
    clear: { type: String, default: "true" },
  };
  connect() {
    var defaultConfig = {
      placeholder: this.placeholderValue,
      language: "pt-BR",
      allowClear: this.clearValue == "true",
      tags: this.hasTagsTarget,
    };

    this.selectTargets.forEach((select) => {
      $(`#${select.id}`)
        .select2(defaultConfig)
        .on("select2:unselecting", function (e) {
          var self = $(this);
          setTimeout(function () {
            self.select2("close");
          }, 0);
        });
    });

    this.selectTargets.forEach((select) => {
      $(`#${select.id}`).on("select2:open", (e) => {
        setTimeout(function () {
          document
            .querySelector(".select2-container--open .select2-search__field")
            .focus();
        }, 0);
      });
    });

    document.addEventListener("turbolinks:before-cache", () => {
      this.selectTargets.forEach((el) => {
        let select = $(el);
        if (select.hasClass("select2-hidden-accessible"))
          select.select2("destroy");
      });
    });
  }
}
