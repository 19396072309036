require("select2");
require("select2/dist/js/i18n/pt-BR");
import "select2/dist/css/select2.css";

document.addEventListener("turbolinks:load", () => {
  var submit = () => {
    $("#submit-filter").trigger("click");
  };

  var defaultConfig = {
    placeholder: "Filtrar...",
    language: "pt-BR",
    allowClear: true,
    tags: true,
  };

  var filters = $("select[id^='q_']");

  filters.each((index, el) => {
    $("select[id^='q_']")
      .select2(defaultConfig)
      .on("select2:unselecting", function (e) {
        var self = $(this);
        setTimeout(function () {
          self.select2("close");
        }, 0);
      })
      .change(submit);

    $(`#${el.id}`).on("select2:open", (e) => {
      setTimeout(function () {
        let search_inputs = document.getElementsByClassName("select2-search__field")
        search_inputs[search_inputs.length - 1].focus();
      }, 0);
    });
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  var filters = $("select[id^='q_']");

  filters.each((index, el) => {
    var select = $(el);
    if (select.hasClass("select2-hidden-accessible")) select.select2("destroy");
  });
});
