document.addEventListener("turbolinks:load", () => {
  formError = document.querySelectorAll(".field_with_errors");
  formError.forEach((form) => {
    if (
      form.firstElementChild.tagName === "INPUT" ||
      form.firstElementChild.tagName === "SELECT"
    )
      form.firstElementChild.classList.add("is-danger");
  });
});
