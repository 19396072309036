document.addEventListener("turbolinks:load", () => {
  clientField = document.querySelector("#contract_client_id");
  vivoField = document.querySelector("#vivo");
  claroField = document.querySelector("#claro");

  if (clientField && claroField && vivoField) {
    function selectedField(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].selected) return list[i].innerText;
      }
    }

    function toggleFields(option) {
      if (selectedField(option) == "Vivo") {
        vivoField.classList.toggle("is-hidden");
        claroField.classList.toggle("is-hidden");
      } else {
        vivoField.classList.toggle("is-hidden");
        claroField.classList.toggle("is-hidden");
      }
    }

    if (selectedField(clientField.children) == "Vivo") {
      vivoField.classList.toggle("is-hidden");
    } else {
      claroField.classList.toggle("is-hidden");
    }

    clientField.addEventListener("change", () => {
      toggleFields(selectedField(clientField.children));
    });
  }
});
