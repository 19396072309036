import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contract", "period", "label"];

  connect() {
    var select = $('[id$=contract_id]');
    select.attr("data-action", "change->projects#validity");
    select.on("select2:select", function () {
      let event = new Event("change", {
        bubbles: true,
      });
      this.dispatchEvent(event);
    });
  }

  validity() {
    this.periodTargets.forEach((v) => {
      v.classList.add("is-hidden");
      if (this.contractTarget.value) {
        this.labelTarget.classList.remove("is-hidden");
      } else {
        this.labelTarget.classList.add("is-hidden");
      }
      if (this.contractTarget.value === v.getAttribute("value")) {
        v.classList.remove("is-hidden");
      }
    });
  }
}
